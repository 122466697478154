import Box from "@mui/material/Box";
import { styled as styling } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Color from "../../../assets/colors";

export const CardContainer = styling(Box)((props) => ({
  backgroundColor: Color.lightPurple,
  border: "1px solid" + Color.black,
  borderRadius: "0.5rem",

  position: "relative",
  justifyContent: "space-between",
  display: "flex",
  // display: props.visibility ? "flex" : "none",
  justifyContent: "center",
  alignItems: "center",

  height: "167px",
  width: "100%",
  overflow: "clip",

  "@media (max-width: 640px)": {
    flexDirection: "column-reverse",
    border: "1px solid" + Color.grey,
    height: "296px",
  },
}));

export const BannerInfoContainer = styling("div")({
  background: "linear-gradient(10deg, #512ACC 11.22%, #2C2829 112.9%)",

  display: "flex",
  flex: "1 0 auto",
  alignItems: "center",
  justifyContent: "center",

  height: "100%",
  maxWidth: "488px",
  width: "100%",
  padding: "2rem 1.75rem 2rem 1.5rem",

  "@media (max-width:1279px)": {
    maxWidth: "464px",
    padding: "1rem 1.125rem",
  },

  "@media (max-width: 640px)": {
    background: "linear-gradient(249deg, #512ACC 14.74%, #2C2829 110.13%)",

    flexDirection: "column",
    gap: "0.5rem",

    height: "58%",
    maxWidth: "464px",
    width: "100%",
    padding: "0.875rem 1.5rem 1rem",
  },
});

export const BannerMainInfoContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.5rem",
});

export const BannerTextContainer = styling("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  width: "100%",
});

export const BannerArtContainer = styling("div")({
  backgroundColor: Color.lightPurple,
  width: "100%",

  "@media (max-width:1279px)": {
    width: "50%",
  },

  "@media (max-width: 640px)": {
    height: "42%",
    width: "100%",
  },
});

export const QrContainer = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  borderRadius: "0.75rem",
  border: "1px solid" + Color.black,
  backgroundColor: Color.white,

  height: "132px",
  width: "132px",
  padding: "0.75rem",

  "@media (max-width: 640px)": {
    borderRadius: "0.5rem",
    height: "96px",
    width: "96px",
    padding: "0.5rem",
  },
});

export const DownloadButtonsContainer = styling(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.25rem",
}));

export const BoxContainer = styling(Box)({
  display: "flex",
  width: "100%",
});

export const TextStyled = styling(Typography)({});

export const ContentText = styling(Typography)({
  color: "rgba(0, 0, 0, 0.60)",
  fontSize: "0.875rem",
  lineHeight: "24px",
  letterSpacing: "0.15px",

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    lineHeight: "20px",
  },
});

export const BannerTitle = styling(Typography)({
  color: Color.white,
  fontFamily: "Manrope",
  fontSize: "1.25rem",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "wrap",

  "@media (max-width: 640px)": {
    fontSize: "0.875rem",
    lineHeight: "21px",
  },
});

export const BannerSubtitle = styling(Typography)({
  color: Color.white,
  fontFamily: "Manrope",
  fontSize: "1rem",
  fontWeight: 500,
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textWrap: "wrap",

  "@media (max-width: 640px)": {
    fontSize: "0.625rem",
    lineHeight: "15px",
  },
});

export const LinkStyled = styling("a")((props) => ({
  color: Color.hiredlyPurple,
  fontWeight: "700",
  textDecoration: props.textDecoration,

  "&:hover": {
    cursor: "pointer",
  },
}));

export const DownloadChip = styling("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "0.25rem",

  color: Color.black,
  backgroundColor: Color.white,
  border: "0.5px solid" + Color.black,
  borderRadius: "0.375rem",

  width: "91px",
  padding: "0.26rem 0.4575rem",
  userSelect: "none",
});

export const QrImage = styling("img")({
  height: "110px",
  width: "110px",

  "@media (max-width: 640px)": {
    height: "80px",
    width: "80px",
  },
});

export const ArtImage = styling("img")({
  position: "absolute",
  top: -25,
  left: 424,
  objectFit: "contain",

  height: "167%",
  width: "auto",

  "@media (max-width: 640px)": {
    top: -33,
    left: "53%",
    transform: "translateX(-50%)",
    height: "65%",
  },
});

export const DownloadIcon = styling("img")({
  backgroundColor: Color.white,
  height: "18px",
  width: "auto",
});
