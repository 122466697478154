import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";

export const NoResultsContainer = styling(Grid)((props) => ({
  width: "100%",
  height: "calc(100vh - 180px)",
  padding: "0 2% 2% 2%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  background: "transparent",

  [props.theme.breakpoints.down("mobileL")]: {
    padding: "0 5%",
    height: "85vh",
    background: Color.backgroundGrey,
    justifyContent: "center",
  },
}));

export const NoResultsTextContainer = styling(Grid)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export const SearchTipsContainer = styling(Grid)((props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "left",
  alignItems: "left",
  padding: "15px 0 0 0",
  ul: {
    padding: "0 12px",
    margin: "0",
  },

  [props.theme.breakpoints.down("mobileL")]: {
    padding: "15px 25px 0 25px",
  },
}));

export const TitleStyled = styling(Typography)((props) => ({
  fontSize: FontSize.desktopBody,
  span: {
    fontWeight: FontSize.bold,
  },
  [props.theme.breakpoints.down("mobileL")]: {
    textAlign: "center",
    fontSize: FontSize.desktopBody,
  },
}));

export const SubtitleStyled = styling(Typography)((props) => ({
  fontSize: FontSize.desktopCaption,
  span: {
    fontWeight: FontSize.bold,
  },
}));

export const LinkStyled = styling(Link)({
  color: Color.hiredlyPurple,
  fontStyle: FontSize.italic,
  fontWeight: FontSize.bold,
  textDecoration: "underline",
  cursor: "pointer",
});

// Misc. Components //
export const ImageStyled = styling("img")({
  width: 270,
  height: 270,
});
