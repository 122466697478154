import { decode } from "html-entities";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as config from "../../../config/config";
import { AiJobSummarySkeleton } from "../Skeleton/Skeleton";
import {
  AiArrowImage,
  AiSparklesIcon,
  AiSummaryTail,
  AshleyImage,
  BetaText,
  BoxContainer,
  HoverIcon,
  JobDetailsCard,
  JobDetailsContainer,
  JobTitleText,
  LinkStyled,
  PlaceholderImage,
  PlaceholderWrapper,
  PromotedContainer,
  PromotedIcon,
  PromotedText,
  ShowMoreText,
  SummaryCard,
  SummaryContent,
  SummaryWrapper,
  TextStyled,
} from "./styles";

function AiJobSummary(props) {
  const {} = props;

  const job = useSelector((state) => state.jobs.hoverJob);
  const fetchingJobs = useSelector((state) => state.jobs.fetchingJobList);

  const [showFullSkills, setShowFullSkills] = useState(false);
  const [showFullBenefits, setShowFullBenefits] = useState(false);

  // To convert the hexadecimal characters and HTML entities from backend to string format
  const convertedGptSummary = useMemo(() => {
    if (!job?.gptSummary) return "";

    let regexHex = /&#x([0-9a-fA-F]+);/g;

    let result = job.gptSummary.replace(regexHex, (_, hexValue) => {
      let char = String.fromCharCode(parseInt(hexValue, 16));
      return char;
    });

    return decode(result);
  }, [job?.gptSummary]);

  const truncatedSkills = useMemo(() => {
    if (!job?.skills?.length) return "";

    let skillsString = job.skills.map((skill) => skill.name).join(", ");
    if (skillsString.length <= 70) return skillsString;

    let truncated = skillsString.slice(0, 70).trim();
    let lastCommaIndex = truncated.lastIndexOf(",");
    if (lastCommaIndex > 0) {
      truncated = truncated.slice(0, lastCommaIndex);
    }

    const remainingCount = job.skills.length - truncated.split(",").length;

    return showFullSkills ? (
      skillsString
    ) : (
      <>
        {truncated}...{" "}
        <ShowMoreText onClick={() => setShowFullSkills(true)}>
          +{remainingCount} more
        </ShowMoreText>
      </>
    );
  }, [job?.skills, showFullSkills]);

  const truncatedBenefits = useMemo(() => {
    if (!job?.company?.benefits?.length) return "";

    let benefitsString = job?.company?.benefits
      ?.map((benefit) => benefit?.title)
      .join(", ");
    if (benefitsString.length <= 70) return benefitsString;

    let truncated = benefitsString.slice(0, 70).trim();
    let lastCommaIndex = truncated.lastIndexOf(",");
    if (lastCommaIndex > 0) {
      truncated = truncated.slice(0, lastCommaIndex);
    }

    const remainingCount =
      job?.company?.benefits.length - truncated.split(",").length;

    return showFullBenefits ? (
      benefitsString
    ) : (
      <>
        {truncated}...{" "}
        <ShowMoreText onClick={() => setShowFullBenefits(true)}>
          +{remainingCount} more
        </ShowMoreText>
      </>
    );
  }, [job?.company?.benefits, showFullBenefits]);

  useEffect(() => {
    setShowFullBenefits(false);
    setShowFullSkills(false);
  }, [job]);

  return (
    <SummaryWrapper>
      {fetchingJobs ? (
        <AiJobSummarySkeleton />
      ) : Object.keys(job).length > 0 ? (
        <SummaryCard $JobSlotMax={job?.jobSlotType === "job_slot_max"}>
          {job?.jobSlotType === "job_slot_max" && (
            <PromotedContainer>
              <PromotedIcon />
              <PromotedText>Promoted Jobs</PromotedText>
              <PromotedIcon />
            </PromotedContainer>
          )}
          <SummaryContent $JobSlotMax={job?.jobSlotType === "job_slot_max"}>
            <JobTitleText>
              {job?.title} @{" "}
              <LinkStyled
                href={"/companies/" + job?.company?.slug}
                target="_blank"
              >
                {job?.company?.name}
              </LinkStyled>
            </JobTitleText>

            <BoxContainer flexDirection={"column"}>
              <BoxContainer alignItems={"center"}>
                <BoxContainer alignItems={"flex-end"} gap={"0.25rem"}>
                  <AshleyImage
                    src={
                      config.assetDomain + "/images/hiredly/ashley-image.svg"
                    }
                    alt="ashley-image"
                  />
                  <BoxContainer alignItems={"center"} gap={"0.25rem"}>
                    <AiSparklesIcon />
                    <TextStyled
                      fontSize={"1rem"}
                      fontWeight={"600"}
                      lineHeight={"1.5rem"}
                    >
                      AI-generated summary
                    </TextStyled>
                  </BoxContainer>
                </BoxContainer>
                <BetaText>BETA</BetaText>
              </BoxContainer>
              <JobDetailsContainer></JobDetailsContainer>
            </BoxContainer>
            {convertedGptSummary !== "" && (
              <BoxContainer flexDirection={"column"}>
                <AiSummaryTail />
                <JobDetailsCard>{convertedGptSummary}</JobDetailsCard>
              </BoxContainer>
            )}
            {job?.skills?.length > 0 && (
              <JobDetailsCard type={"skills"}>
                <b>Skills: </b>
                {truncatedSkills}
              </JobDetailsCard>
            )}
            {job?.company?.benefits?.length > 0 && (
              <JobDetailsCard type={"benefits"}>
                <b>Benefits: </b>
                {truncatedBenefits}
              </JobDetailsCard>
            )}
          </SummaryContent>
        </SummaryCard>
      ) : (
        <AiSummaryPlaceholder />
      )}
    </SummaryWrapper>
  );
}

function AiSummaryPlaceholder(props) {
  return (
    <PlaceholderWrapper>
      <PlaceholderImage
        src={config.assetDomain + "/images/hiredly/ai-summary-placeholder.webp"}
      />
      <BoxContainer
        justifyContent={"center"}
        alignItems={"center"}
        gap={"0.5rem"}
        marginTop={"1rem"}
      >
        <HoverIcon />
        <TextStyled
          color={"rgba(0, 0, 0, 0.60)"}
          fontSize={"0.875rem"}
          lineHeight={"1.25rem"}
          letterSpacing={"0.112px"}
        >
          Hover over for a quick job overview.
        </TextStyled>
      </BoxContainer>
      <AiArrowImage
        src={config.assetDomain + "/images/hiredly/ai-arrow.svg"}
        alt="arrow-image"
      />
    </PlaceholderWrapper>
  );
}

export default AiJobSummary;
