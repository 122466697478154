import BellIcon from "@heroicons/react/24/solid/BellIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

// Grid Components //
export const JobAlertContainer = styling("div")((props) => ({
  background: props.$active
    ? "linear-gradient(91deg, #170C39 -7.37%, #170C39 7.22%, #34226B 58.35%, #7237BE 87.03%, #F2A647 107.34%)"
    : "linear-gradient(276deg, rgba(242, 175, 41, 0.40) -51.84%, rgba(242, 175, 41, 0.40) -29.88%, rgba(242, 125, 41, 0.70) -7.65%, rgba(81, 42, 204, 0.60) 31.08%, #35198D 100%)",
  border: `2px solid ${Color.chipGrey}`,
  borderRadius: "1rem",
  boxShadow: "2px 2px 0px 0px ${Color.chipGrey}",

  display: props?.open ? "flex" : "none",
  justifyContent: "space-between",
  alignItems: "center",

  padding: "0.75rem 1.25rem",

  [props.theme.breakpoints.down("sm")]: {
    padding: "0.625rem 0.75rem",
  },
}));

export const ContentContainer = styling("div")((props) => ({
  display: "flex",
  flex: "1 0 auto",
  justifyContent: "space-between",
  alignItems: "center",

  maxHeight: "2.25rem",
  height: "100%",
  width: "100%",
}));

export const LabelContainer = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  gap: props.$active ? "1rem" : "0.375rem",
}));

export const ButtonContainer = styling("div")((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: props.$active ? "1rem" : "0.75rem",
  // width: "100%",
}));

// Typography Components //
export const AlertCopyText = styling(Typography)((props) => ({
  color: Color.white,
  fontSize: "0.875rem",
  fontWeight: 700,
  lineHeight: "1.5rem",
  letterSpacing: "0.1px",
  whiteSpace: "nowrap",

  [props.theme.breakpoints.down("sm")]: {
    lineHeight: "1.375rem",
    whiteSpace: "wrap",
  },
}));

// Misc. Components //
export const NotificationsIconStyled = styling(BellIcon)((props) => ({
  color: Color.white,
  width: "28px",
  height: "28px",

  [props.theme.breakpoints.down("desktop")]: {
    width: "22px",
    height: "22px",
  },
}));

export const CheckCircleIconStyled = styling(CheckCircleIcon)({
  color: Color.white,
  width: "30px",
  height: "30px",
});

export const CloseIconStyled = styling(XMarkIcon)((props) => ({
  color: Color.white,
  width: "18px",
  height: "18px",
}));

export const PaperPlaneIconStyled = styling(PaperAirplaneIcon)({
  color: Color.white,
  width: "16px",
  height: "16px",
});

// Icon Button Component //
export const IconButtonStyled = styling(IconButton)((props) => ({
  margin: "0",
  padding: "0",
}));

// Input Component //
export const InputBaseStyled = styling(InputBase)((props) => ({
  backgroundColor: Color.white,
  border: `2px solid ${Color.black}`,
  boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",

  display: "flex",
  alignItems: "center",
  justifyContent: "end",
  gap: "10px",

  fontSize: "0.875rem",

  height: "40px",
  width: "100%",
  padding: "0.375rem 0.375rem 0.375rem 1rem",
}));

export const SetAlertButtonStyled = styling(ButtonBase)((props) => ({
  backgroundColor: Color.white,
  color: Color.black,
  border: `2px solid ${Color.black}`,
  borderRadius: "8px",

  fontSize: "0.875rem",
  fontWeight: 700,
  textTransform: "capitalize",

  height: "36px",
  width: "160px",
  padding: "0 1rem",

  "&:hover": {
    border: `2px solid ${Color.hiredlyPurple}`,
    background: Color.white,
    color: Color.hiredlyPurple,
  },

  [props.theme.breakpoints.down("sm")]: {
    padding: "0px 6px",
    width: "94px", // Adjust maxWidth for mobile if needed
  },
}));

export const InputBaseAdornmentStyled = styling("div")({
  backgroundColor: Color.black,
  color: Color.white,
  borderRadius: "4px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  fontSize: "0.875rem",
  lineHeight: "1.5rem",
  letterSpacing: "0.15px",

  maxHeight: "29px",
  height: "100%",
  padding: "0.625rem",
  margin: "0 0.125rem 0 0",
});
