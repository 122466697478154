import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import HandThumbDownIcon from "@heroicons/react/24/outline/HandThumbDownIcon";
import HandThumbUpIcon from "@heroicons/react/24/outline/HandThumbUpIcon";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

export const CardContainer = styling(Box)((props) => ({
  display: props.show ? "flex" : "none",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "0.5rem",

  backgroundColor: Color.white,
  border: `1px solid ${Color.secondaryGrey}`,
  borderRadius: "0.625rem",
}));

export const BoxContainer = styling(Box)({
  display: "flex",
  width: "100%",
});

export const TextStyled = styling(Typography)({});

export const ErrorTextStyled = styling(Typography)((props) => ({
  position: "absolute",
  right: "0",
  bottom: "0",

  color: props.$error ? Color.error : "rgba(0, 0, 0, 0.38)",
  fontSize: "0.875rem",
  margin: "1rem 1.25rem",
  zIndex: 10,
}));

export const InputStyled = styling(TextField)({
  width: "100%",
  backgroundColor: Color.white,

  "& .MuiInputBase-input": {
    "&::placeholder": {
      fontStyle: "italic",
    },
  },

  "& .MuiOutlinedInput-root": {
    minHeight: "92px",
    padding: "1rem 1.25rem",

    "@media (max-width: 640px)": {
      minHeight: "88px",
    },

    "& fieldset": {
      border: `2px solid ${Color.secondaryGrey}`,
    },
    "&:hover fieldset": {
      border: `2px solid ${Color.buttonBorderPurple}`,
    },
    "&.Mui-focused fieldset": {
      border: `2px solid ${Color.buttonBorderPurple}`,
    },
  },
});

export const OutlineButton = styling(ButtonBase)((props) => ({
  border: `1px solid rgba(0, 0, 0, 0.60)`,
  borderRadius: "0.25rem",

  height: "48px",
  width: "48px",
  padding: "0.5rem",

  transition: "border 0.05s ease-in-out, box-shadow 0.175s ease-in-out",

  "&:hover": {
    color: props.$success ? Color.success : Color.error,
    backgroundColor: props.$success
      ? "rgba(144, 185, 56, 0.10)"
      : "rgba(247, 84, 67, 0.10)",
    border: "none",
    boxShadow: `0px 0px 0px 2px ${
      props.$success ? Color.success : Color.error
    }`,
  },

  ...(props.$selected && {
    color: Color.white,
    backgroundColor: props.$success ? Color.success : Color.error,
    border: `2px solid ${props.$success ? Color.success : Color.error}`,
  }),
}));

export const TextButton = styling(ButtonBase)({
  color: "rgba(0, 0, 0, 0.60)",

  fontSize: "1rem",
  fontWeight: "700",

  height: "48px",
  padding: "0 1.5rem",

  transition: "all 0.175s ease-in-out",

  "&:hover": {
    textDecoration: "underline",
  },

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    height: "30px",
    padding: "0 1.25rem",
  },
});

export const FilledButton = styling(ButtonBase)({
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  borderRadius: "0.5rem",

  fontSize: "1rem",
  fontWeight: "700",

  height: "48px",
  padding: "0 2.5rem",

  transition: "all 0.175s ease-in-out",

  "&:hover": {
    backgroundColor: Color.darkPurple,
  },

  "@media (max-width: 767px)": {
    fontSize: "0.75rem",
    height: "30px",
  },
});

export const CloseButton = styling(ButtonBase)({});

export const ThumbUpIcon = styling(HandThumbUpIcon)({
  height: "24px",
  width: "24px",
});

export const ThumbDownIcon = styling(HandThumbDownIcon)({
  height: "24px",
  width: "24px",
});

export const CheckIcon = styling(CheckCircleIcon)({
  color: Color.buttonGreen,
  height: "24px",
  width: "24px",
});

export const CloseIcon = styling(XMarkIcon)({
  color: Color.grey,
  height: "24px",
  width: "24px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },
});

export const LoadingIcon = styling(CircularProgress)({
  color: Color.white,
  maxHeight: "24px",
  maxWidth: "24px",
});
