import { useClickAway } from "@uidotdev/usehooks";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useGenerateJobListUrl from "../../../hooks/generateJobListUrl";
import {
  fetchSearchSuggestions,
  updateJobListFilter,
  updateJobSearchKeyword,
} from "../../../redux/actions/job_action";
import {
  BoxContainer,
  ButtonStyled,
  ClearIcon,
  InputBaseStyled,
  NavbarSearchStyled,
  SearchDropdownContainer,
  SearchIcon,
  SearchSuggestionContainer,
} from "./styles";

function SearchBar(props) {
  const { searchKeyword } = props;

  const dispatch = useDispatch();
  const router = useRouter();
  const { filterToSlug } = useGenerateJobListUrl();

  const jobListFilter = useSelector((state) => state.jobs?.jobListFilter);
  const jobFilterKeyword = jobListFilter?.keyword;
  const currentSearch = useSelector((state) => state.jobs?.jobSearchKeyword);
  const searchSuggestion = useSelector((state) => state.jobs?.searchData);

  const [searchBarFocus, setSearchBarFocus] = useState(false);

  const searchbarRef = useClickAway(() => {
    setSearchBarFocus(false);
  });

  function handleFocusSearch(event) {
    event.preventDefault();
    event.stopPropagation();

    setSearchBarFocus(true);
  }

  function handleInputSearch(event) {
    const keyword = event.target.value;
    dispatch(updateJobSearchKeyword({ keyword: keyword }));
    debounceSearchSuggestions({ keyword: keyword });
  }

  const debounceSearchSuggestions = debounce((params) => {
    dispatch(fetchSearchSuggestions(params));
  }, 300);

  function boldMatchingText(suggestion) {
    const keyword = currentSearch.toLowerCase();

    const lowerCaseSuggestion = suggestion.toLowerCase();
    const index = lowerCaseSuggestion.indexOf(keyword);

    if (!keyword || index === -1) {
      return (
        <SearchSuggestionContainer
          onClick={() => handleTriggerSearch(suggestion, true)}
        >
          {suggestion}
        </SearchSuggestionContainer>
      );
    }

    const beforeMatch = suggestion.substring(0, index);
    const match = suggestion.substring(index, index + keyword.length);
    const afterMatch = suggestion.substring(index + keyword.length);

    return (
      <SearchSuggestionContainer
        onClick={() => handleTriggerSearch(suggestion)}
      >
        <span>
          {beforeMatch}
          <b>{match}</b>
          {afterMatch}
        </span>
      </SearchSuggestionContainer>
    );
  }

  function handleTriggerSearch(searchTerm, isSuggestion = false) {
    scrollTo(0, 0);
    setSearchBarFocus(false);

    const query = router.query;

    dispatch(updateJobListFilter({ keyword: searchTerm })).then(() => {
      dispatch(updateJobSearchKeyword({ keyword: searchTerm }));
      if (isSuggestion) {
        sendTrackingEvent({
          event: "CE_search-job-suggestion-job-list",
          "search-term": `${searchTerm}`,
        });
      } else {
        sendTrackingEvent({
          event: "CE_search-job-job-list",
          "search-term": `${searchTerm}`,
        });
      }

      const { filter, ...queryParams } = query;

      const cFilter = jobListFilter;
      cFilter.keyword = searchTerm;

      const { slug } = filterToSlug(cFilter);

      //[JSW-2992] JLP: Navigate to FYP when press on clear in search bar

      router.push(
        {
          pathname: slug,
          query: queryParams,
        },
        undefined,
        { shallow: true }
      );
    });
  }

  function handleEnterSearch(event) {
    if (event.key === "Enter") {
      handleTriggerSearch(currentSearch);
    }
  }

  function handleClearSearch(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    handleTriggerSearch("");
  }

  useEffect(() => {
    if (searchKeyword !== undefined) {
      if (searchKeyword) {
        dispatch(updateJobSearchKeyword({ keyword: searchKeyword }));
      }
    }
  }, [dispatch, searchKeyword]);

  return (
    <Fragment>
      <NavbarSearchStyled
        component="div"
        elevation={0}
        $active={searchBarFocus}
      >
        <InputBaseStyled
          ref={searchbarRef}
          placeholder={"Search Jobs"}
          inputProps={{ "aria-label": "discover job search" }}
          onClick={handleFocusSearch}
          onChange={handleInputSearch}
          onKeyDown={handleEnterSearch}
          value={currentSearch}
        />
        <BoxContainer alignItems={"center"} gap={"0.25rem"}>
          {jobFilterKeyword?.length > 0 && (
            <ButtonStyled onClick={handleClearSearch}>
              <ClearIcon />
            </ButtonStyled>
          )}

          <ButtonStyled
            aria-label="search button"
            onClick={() => handleTriggerSearch(currentSearch)}
          >
            <SearchIcon />
          </ButtonStyled>
        </BoxContainer>
      </NavbarSearchStyled>
      {searchBarFocus &&
      currentSearch?.length > 2 &&
      searchSuggestion?.length > 0 ? (
        <SearchDropdownContainer ref={searchbarRef}>
          {searchSuggestion.map((data, index) => {
            return <div key={index}>{boldMatchingText(data.name)}</div>;
          })}
        </SearchDropdownContainer>
      ) : null}
    </Fragment>
  );
}

export default SearchBar;
