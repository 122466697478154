import MagnifyingGlassIcon from "@heroicons/react/16/solid/MagnifyingGlassIcon";
import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";

// Box Component //
export const NavbarSearchStyled = styling(Box)((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  backgroundColor: Color.white,
  border: props.$active
    ? `1px solid ${Color.white}`
    : `1px solid ${Color.secondaryGrey}`,
  boxShadow: props.$active ? `0px 0px 0px 2px ${Color.hiredlyPurple}` : "none",
  borderRadius: "0.5rem",

  transition:
    "all 0.15s ease-in-out, border 0.025s ease-in-out, box-shadow 0.175s ease-in-out",

  height: "2.25rem",
  width: "100%",
  maxWidth: "30rem",
  padding: "0.375rem 0.5rem 0.375rem 1rem",

  "@media (max-width: 1280px)": {
    height: "2.5rem",
  },
}));

export const BoxContainer = styling(Box)({
  display: "flex",
});

// Button Component //
export const ButtonStyled = styling(ButtonBase)((props) => ({
  backgroundColor:
    props["aria-label"] === "search button"
      ? Color.hiredlyPurple
      : "transparent",
  color: Color.white,

  borderRadius: "0.375rem",

  height: "1.5rem",
  minWidth: "1.5rem",
  padding: "0.25rem",

  transition: "all 0.15s ease-in-out",

  "&:hover": {
    backgroundColor:
      props["aria-label"] === "search button"
        ? Color.darkPurple
        : "transparent",
  },

  "@media (max-width: 1280px)": {
    height: "1.5rem",
    minWidth: "1.5rem",
  },
}));

// Input Component //
export const InputBaseStyled = styling(InputBase)((props) => ({
  width: "100%",
  padding: "0",
}));

// Grid components //
export const SearchDropdownContainer = styling(Grid)((props) => ({
  position: "absolute",
  top: "55px",
  zIndex: 20,
  backgroundColor: Color.white,
  width: "100%",
  maxWidth: "370px",
  borderRadius: "10px",
  boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
  padding: "10px",

  "@media (max-width: 1279px)": {
    top: "184px",
  },
}));

export const SearchSuggestionContainer = styling(Grid)({
  padding: "10px 15px",
  cursor: "pointer",
  borderRadius: "30px",
  marginBottom: "5px",

  ":hover": {
    backgroundColor: Color.lightGrey,
    cursor: "pointer",
  },
});

// Icon Component //
export const SearchIcon = styling(MagnifyingGlassIcon)({
  color: Color.white,
  height: "16px",
  width: "16px",

  "@media (max-width: 1280px)": {
    height: "16px",
    width: "16px",
  },
});

export const ClearIcon = styling(XMarkIcon)({
  color: Color.darkGrey,
  height: "22px",
  width: "22px",
});
