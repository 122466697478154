import useMediaQuery from "@mui/material/useMediaQuery";
import dayjs from "dayjs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchFeedback } from "../../../redux/actions/job_action";
import {
  BoxContainer,
  CardContainer,
  CheckIcon,
  CloseButton,
  CloseIcon,
  ErrorTextStyled,
  FilledButton,
  InputStyled,
  LoadingIcon,
  OutlineButton,
  TextButton,
  TextStyled,
  ThumbDownIcon,
  ThumbUpIcon,
} from "./styles";

function JobSearchFeedbackCard() {
  const isMobile = useMediaQuery("(max-width: 640px)");

  const dispatch = useDispatch();

  const [show, setShow] = useState(true); // Toggle the visibility of the card
  const [selected, setSelected] = useState(null); // Toggle the selected option

  const [feedback, setFeedback] = useState(""); // for text input
  const [isLoading, setIsLoading] = useState(false); // for loading state

  const user = useSelector((state) => state?.user?.user);
  const submitState = useSelector(
    (state) => state.jobs.searchFeedback.feedbackSubmit
  );

  function handleSelect(state) {
    setSelected(state);
  }

  function handleFeedbackChange(event) {
    setFeedback(event.target.value);
  }

  async function handleSubmit(event) {
    setIsLoading(true);
    event.preventDefault();

    const formData = new FormData();
    formData.append("Time", dayjs().format("YYYY-MM-DD HH:mm:ss").toString());
    formData.append("User_id", user?.id ?? "empty");
    formData.append("Email", user?.email ?? "empty");
    formData.append("Rating", selected);
    formData.append("Feedback", feedback);

    fetch(process.env.SEARCH_FEEDBACK_GSHEET, {
      method: "POST",
      body: formData,
      // mode: "no-cors",
    })
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          dispatch(
            updateSearchFeedback({ feedbackSubmit: true, showCard: true })
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleSkip() {
    setSelected(null);
    setFeedback("");
  }

  function handleClose() {
    setShow(false);
    dispatch(updateSearchFeedback({ feedbackSubmit: true, showCard: false }));
  }

  return (
    <CardContainer
      flexDirection={selected === null ? "row" : "column"}
      show={show}
    >
      {submitState ? (
        <BoxContainer
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={"1.25rem 1rem"}
        >
          <BoxContainer alignItems={"center"} gap={"0.5rem"}>
            <CheckIcon />
            <TextStyled
              fontSize={isMobile ? "0.875rem" : "1.125rem"}
              lineHeight={isMobile ? "1.25rem" : "1.5rem"}
              letterSpacing={isMobile ? "0.112px" : "0.15px"}
            >
              Thanks again for your <b>feedback!</b>
            </TextStyled>
          </BoxContainer>
          <CloseButton onClick={() => handleClose()}>
            <CloseIcon />
          </CloseButton>
        </BoxContainer>
      ) : (
        <BoxContainer
          flexDirection={selected === null ? "row" : "column"}
          padding={"1rem"}
        >
          <BoxContainer flexDirection={"column"} gap={"0.25rem"}>
            <TextStyled
              fontSize={"1.125rem"}
              fontWeight={"500"}
              lineHeight={"1.5rem"}
            >
              {selected
                ? "Thank you for your feedback!"
                : "Found what you were looking for?"}
            </TextStyled>
            <TextStyled
              fontSize={"0.875rem"}
              fontWeight={"400"}
              lineHeight={isMobile ? "1.25rem" : "1.5rem"}
              letterSpacing={isMobile ? "0.112px" : "0.15px"}
            >
              {selected
                ? "Would you like to tell us why?"
                : "Your feedback helps us improve search results."}
            </TextStyled>
          </BoxContainer>
          {selected === null ? (
            <BoxContainer gap={"0.75rem"} sx={{ width: "auto" }}>
              <OutlineButton
                onClick={() => handleSelect("yes")}
                $selected={selected === "yes"}
                $success
              >
                <ThumbUpIcon />
              </OutlineButton>
              <OutlineButton
                onClick={() => handleSelect("no")}
                $selected={selected === "no"}
              >
                <ThumbDownIcon />
              </OutlineButton>
            </BoxContainer>
          ) : (
            <BoxContainer
              component={"form"}
              noValidate
              flexDirection={"column"}
              gap={"1rem"}
              onSubmit={(event) => handleSubmit(event)}
            >
              <BoxContainer
                position={"relative"}
                flexDirection={"column"}
                alignItems={"flex-end"}
                margin={"0.5rem 0 0"}
              >
                <InputStyled
                  placeholder="Comment here. (Optional)"
                  type={"text"}
                  multiline
                  name="feedback"
                  id="feedback"
                  onChange={(event) => handleFeedbackChange(event)}
                  inputProps={{
                    style: {
                      color: "rgba(0, 0, 0, 0.60)",
                      fontSize: "0.875rem",
                      lineHeight: "1.25rem",
                      letterSpacing: "0.112px",
                      minHeight: "60px",

                      "@media (max-width: 640px)": {
                        minHeight: "56px",
                      },
                    },
                    disableUnderline: true,
                    maxLength: 200,
                  }}
                />
                <ErrorTextStyled $error={feedback?.length === 200}>
                  {feedback?.length}/200
                </ErrorTextStyled>
              </BoxContainer>
              <BoxContainer justifyContent={"flex-end"} gap={"1rem"}>
                <TextButton variant="outlined" onClick={() => handleSkip()}>
                  Skip
                </TextButton>
                <FilledButton
                  variant="outlined"
                  type={"submit"}
                  disabled={isLoading}
                >
                  {isLoading ? <LoadingIcon /> : "Submit"}
                </FilledButton>
              </BoxContainer>
            </BoxContainer>
          )}
        </BoxContainer>
      )}
    </CardContainer>
  );
}

export default JobSearchFeedbackCard;
