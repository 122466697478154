import { useDispatch } from "react-redux";
import {
  ButtonContainer,
  CheckStyled,
  CheckboxContainer,
  CheckboxStyled,
  CheckedIcon,
  FilterActionButton,
  UncheckedIcon,
} from "../JobSearchOptions/styles";
import {
  BoxContainer,
  ButtonStyled,
  JobSpecializationMenuContainer,
  LeftArrowIcon,
  TextStyled,
} from "./styles";

function JobSpecializationMenu(props) {
  const {
    jobListFilter,
    filterArray,
    checkboxValueChange,
    selectedFilters,
    updateSelectAllJobFilter,
    closeSubMenu,
    show,
  } = props;

  const dispatch = useDispatch();

  let specializationIds = [];

  function handleSelectAll() {
    let cJobListFilter = { ...jobListFilter };

    filterArray?.forEach((obj) => {
      specializationIds.push(obj.id);
    });

    if (cJobListFilter?.trackIds?.length > 0) {
      cJobListFilter["trackIds"] = [
        ...cJobListFilter["trackIds"],
        ...specializationIds,
      ];
    } else {
      cJobListFilter["trackIds"] = specializationIds;
    }

    dispatch(updateSelectAllJobFilter(cJobListFilter));
  }

  function handleDeselectAll() {
    let cJobListFilter = { ...jobListFilter };

    cJobListFilter["trackIds"] = cJobListFilter?.trackIds?.filter((item) => {
      return !filterArray?.map((obj) => obj.id)?.includes(item);
    });

    dispatch(updateSelectAllJobFilter(cJobListFilter));
  }

  function isFilterSelected(selectedFilters, filter) {
    if (selectedFilters?.includes(filter)) {
      return true;
    }
    return false;
  }

  function renderSelectAndDeselectAllButton() {
    const cFilter = filterArray?.map((obj) => obj.id);

    const isEmpty = selectedFilters?.some((item) => cFilter?.includes(item));

    return (
      <FilterActionButton
        onClick={isEmpty ? () => handleDeselectAll() : () => handleSelectAll()}
        variant="text"
      >
        {isEmpty ? "Deselect All" : "Select All"}
      </FilterActionButton>
    );
  }

  return (
    <JobSpecializationMenuContainer show={show}>
      <ButtonContainer
        justifyContent={"space-between"}
        padding={"0.75rem 1.5rem 0.375rem 0.625rem"}
      >
        <ButtonStyled onClick={closeSubMenu}>
          <LeftArrowIcon />
        </ButtonStyled>
        {renderSelectAndDeselectAllButton()}
      </ButtonContainer>
      <BoxContainer
        key={"specialization-menu"}
        flexDirection={"column"}
        maxHeight={"12.5rem"}
        sx={{ overflowY: "scroll", overflowX: "hidden" }}
      >
        {filterArray?.map((filterItem, index) => {
          return (
            <CheckboxContainer
              key={index}
              onClick={checkboxValueChange("track", filterItem)}
            >
              <CheckboxStyled
                icon={<UncheckedIcon />}
                name={filterItem.title || filterItem.state}
                checked={isFilterSelected(selectedFilters, filterItem?.id)}
                checkedIcon={
                  <CheckedIcon>
                    <CheckStyled />
                  </CheckedIcon>
                }
              />
              <TextStyled
                fontSize={"0.875rem"}
                lineHeight={"1.5rem"}
                letterSpacing={"0.15px"}
                textAlign={"left"}
              >
                {filterItem.name}
              </TextStyled>
            </CheckboxContainer>
          );
        })}
      </BoxContainer>
    </JobSpecializationMenuContainer>
  );
}

export default JobSpecializationMenu;
