import { useSelector } from "react-redux";
import { jobTypes } from "../helpers/constant";

// Pure functions that don't use hooks
function filterToSlug(
  filter,
  specialisationList,
  stateRegionsList,
  jobTypesList
) {
  const { keyword, trackIds, stateRegions, jobTypeIds, globalHire } =
    filter || {};

  const searchSlug = keyword
    ? keyword.toLowerCase().replace(/\s+/g, "-") + "-jobs"
    : "";
  const specSlug = getSpecialisationSlug(trackIds, specialisationList);
  const stateSlug = getStateSlug(stateRegions, stateRegionsList);
  const jobTypeSlug = getJobTypeSlug(jobTypeIds, jobTypesList);
  const globalHireSlug = globalHire ? "global-hire" : "";

  const slugParts = [
    searchSlug,
    globalHireSlug && globalHireSlug + "-jobs",
    specSlug,
    stateSlug,
    jobTypeSlug,
  ].filter(Boolean);

  if (
    slugParts.length > 0 &&
    (slugParts[0] === specSlug || slugParts[0] === stateSlug)
  ) {
    slugParts[0] = "jobs-" + slugParts[0];
  }

  if (
    slugParts.length > 0 &&
    (slugParts[0] === jobTypeSlug || slugParts[0] === globalHireSlug)
  ) {
    slugParts[0] = slugParts[0] + "-jobs";
  }

  return {
    slug: slugParts.length > 0 ? "/" + slugParts.join("/") : "/jobs",
    specSlugExist: specSlug !== "",
  };
}

function getSpecialisationSlug(trackIds, specialisationList) {
  if (!trackIds) return "";

  const specialisation = specialisationList.find((spec) =>
    trackIds.every((id) => spec.subSpecialisations.some((sub) => sub.id === id))
  );

  if (!specialisation) return "";

  const subSpecialisations = specialisation.subSpecialisations.filter((sub) =>
    trackIds.includes(sub.id)
  );

  if (subSpecialisations.length === specialisation.subSpecialisations.length) {
    return "in-" + specialisation.slug;
  }

  if (subSpecialisations.length === 1) {
    return "in-" + specialisation.slug + "/" + subSpecialisations[0].slug;
  }

  return "";
}

function getStateSlug(stateRegions, stateRegionsList) {
  if (!stateRegions || stateRegions.length !== 1) return "";

  const stateRegion = stateRegionsList.find(
    (stateRegion) => stateRegion.state === stateRegions[0]
  );

  return stateRegion ? "in-" + stateRegion.slug : "";
}

function getJobTypeSlug(jobTypeIds, jobTypesList) {
  if (!jobTypeIds || jobTypeIds.length !== 1) return "";

  const jobType = jobTypesList.find(
    (jobType) => parseInt(jobType.id) === jobTypeIds[0]
  );

  return jobType ? jobType.slug : "";
}

// Hook that uses useSelector and returns the necessary data
export function useJobListUrlData() {
  const specialisationList = useSelector((state) => state.jobs.specialisation);
  const stateRegionsList = useSelector((state) => state.jobs.stateRegions);
  const jobTypesList = jobTypes;

  return {
    specialisationList,
    stateRegionsList,
    jobTypesList,
  };
}

// Main function that combines the hook and pure functions
export default function useGenerateJobListUrl() {
  const { specialisationList, stateRegionsList, jobTypesList } =
    useJobListUrlData();

  return {
    filterToSlug: (filter) =>
      filterToSlug(filter, specialisationList, stateRegionsList, jobTypesList),
  };
}
