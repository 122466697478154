import XMarkIcon from "@heroicons/react/20/solid/XMarkIcon";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import GlobeAsiaAustraliaIcon from "@heroicons/react/24/solid/GlobeAsiaAustraliaIcon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import Color from "../../../assets/colors";
import CancelAppIcon from "../../../assets/svg/cancel-app-icon.svg";
import MYFlag from "../../../public/images/MY.svg";
import SGFlag from "../../../public/images/SG.svg";

export const DialogContainer = styling(Dialog)({
  "& .MuiDialog-paper": {
    background: Color.white,
    border: `2px solid ${Color.black}`,
    borderRadius: "1.5rem",

    maxHeight: "36.25rem",
    maxWidth: "37.5rem",

    overflow: "hidden",
    zIndex: 9999,

    "@media (max-width: 640px)": {
      maxHeight: "37.5rem",
      minWidth: "20.5rem",
    },
  },
});

export const BoxContainer = styling(Box)({
  display: "flex",
});

export const TextStyled = styling(Typography)({});

export const SelectContainer = styling(Box)({
  userSelect: "none",
});

export const SelectHeader = styling(Box)((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  backgroundColor: Color.white,
  color: Color.darkGrey,
  boxShadow: props.selected ? `0px 0px 0px 2px ${Color.hiredlyPurple}` : "none",
  border: `1px solid ${Color.grey}`,
  borderRadius: "0.5rem",

  height: "2.5rem",
  padding: "0 1rem",
  overflow: "clip",

  transition: "all 0.15s ease-in-out",

  ...(props.disabled && {
    backgroundColor: "#f5f5f5",
    color: "rgba(0, 0, 0, 0.38))",
    border: `1px solid #f5f5f5`,
    pointerEvents: "none",
  }),

  "&:hover": {
    cursor: "pointer",
  },

  "@media (max-width: 640px)": {
    width: "74.67svw",
  },
}));

export const SelectDropdown = styling(Collapse)((props) => ({
  position: "absolute",
  zIndex: "3",

  backgroundColor: Color.white,
  color: Color.black,

  border: `2px solid ${Color.black}`,
  borderRadius: "0.5rem",

  width: "22.5rem",
  marginTop: "0.1875rem",

  overflow: "ellipsis",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",

  "@media (max-width: 640px)": {
    width: "74.67svw",
  },
}));

export const SelectOption = styling(Button)((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "1rem",

  color: "rgba(0, 0, 0, 0.87)",
  fontSize: "0.875rem",
  fontWeight: 400,
  lineHeight: "1.25rem",
  letterSpacing: "0.112px",
  textTransform: "initial",

  textWrap: "wrap",
  textAlign: "left",
  height: "auto",
  width: "100%",
  padding: "0.5rem 1rem",

  "&:hover": {
    backgroundColor: Color.lightHiredlyPurple,
  },
}));

export const ButtonStyled = styling(ButtonBase)((props) => ({
  color: props.variant === "outline" ? "#2E2E2E" : Color.white,
  backgroundColor: props.variant === "outline" ? Color.white : "#2E2E2E",
  borderRadius: "6.25rem",

  fontSize: "1rem",
  fontWeight: "700",

  height: "2.625rem",
  width: "12.3125rem",
  padding: "0 1rem",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    background: props.variant === "outline" ? Color.lightGrey : Color.black,
    cursor: "pointer",
  },

  "&.Mui-disabled": {
    background: Color.grey,
  },

  "@media (max-width: 640px)": {
    fontSize: "0.75rem",
    height: "1.875rem",
  },
}));

export const CloseButton = styling(ButtonBase)({
  background: "none",
  border: "none",
  height: "24px",
  width: "24px",

  "&:hover": {
    cursor: "pointer",
  },
});

export const CloseIcon = styling(XMarkIcon)({
  color: Color.grey,
  height: "24px",
  width: "24px",

  transition: "all 0.1s ease-in-out",

  "&:hover": {
    color: Color.darkGrey,
  },
});

export const GlobalChip = styling(Chip)({
  color: Color.white,
  backgroundColor: "#F9CA10",

  fontSize: "0.625rem",
  fontWeight: 700,
  lineHeight: "1.25rem",
  letterSpacing: "1.5px",
  textTransform: "uppercase",

  height: "1.5rem",
  width: "auto",
  padding: "0.125rem 0.375rem",
  gap: "0.25rem",

  "& .MuiChip-label": {
    padding: "0",
  },
  "& .MuiChip-icon": {
    color: Color.white,
    height: "20px",
    width: "20px",
    margin: "0",
  },
});

export const MYCountryFlag = styling(MYFlag)({
  width: "24px",
  height: "auto",
  objectFit: "contain",
});

export const SGCountryFlag = styling(SGFlag)({
  width: "24px",
  height: "auto",
  objectFit: "contain",
});

export const DownArrowIcon = styling(ChevronDownIcon)((props) => ({
  color: props.selected ? Color.hiredlyPurple : Color.darkGrey,

  width: "1.25rem",

  transform: props.selected ? "rotate(180deg)" : "rotate(0deg)",
  transition: "all 0.15s ease-in-out",
}));

export const GlobalIcon = styling(GlobeAsiaAustraliaIcon)({});

export const CancelApplicationIcon = styling(CancelAppIcon)({
  height: "120px",
  width: "auto",

  "@media (max-width: 640px)": {
    height: "80px",
  },
});
