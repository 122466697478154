import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectAllJobFilter } from "../../../redux/actions/job_action";
import {
  ButtonContainer,
  FilterActionButton,
} from "../JobSearchOptions/styles";
import JobSpecializationMenu from "./JobSpecializationMenu";
import {
  BoxContainer,
  JobSpecializationContainer,
  MenuBoxStyled,
  MenuContainer,
  NumberedStyled,
  RightArrowIcon,
  SpecializationContainer,
  TextStyled,
} from "./styles";

function JobSpecialization(props) {
  const {
    handleCancelClick,
    checkboxValueChange,
    getFilter,
    filterArray,
    onTriggerLoadJob,
    showSubSpecialisation,
    setShowSubSpecialisation,
  } = props;

  const [currentSpecialization, setCurrentSpecialization] = useState(null);

  const jobListFilter = useSelector((state) => state?.jobs?.jobListFilter);

  const type = "specialisation";

  const selectedFilters = getFilter(jobListFilter, type);

  function openSubMenu(index) {
    setShowSubSpecialisation(true);
    setCurrentSpecialization(index);
  }

  function closeSubMenu() {
    setShowSubSpecialisation(false);
  }

  return (
    <SpecializationContainer>
      <JobSpecializationContainer show={!showSubSpecialisation}>
        <ButtonContainer
          justifyContent={"flex-end"}
          padding={"1rem 1.5rem 0.625rem 0.625rem"}
        >
          <RenderButton
            jobListFilter={jobListFilter}
            filterArray={filterArray}
            selectedFilters={selectedFilters}
            updateSelectAllJobFilter={updateSelectAllJobFilter}
          />
        </ButtonContainer>
        <BoxContainer
          key={"specialization-menu"}
          flexDirection={"column"}
          maxHeight={"12.5rem"}
          sx={{ overflowY: "scroll", overflowX: "hidden" }}
        >
          {filterArray?.map((filterItem, index) => {
            return (
              <MenuContainer onClick={() => openSubMenu(index)}>
                <TextStyled>{filterItem.name}</TextStyled>
                <MenuBoxStyled>
                  {jobListFilter?.trackIds?.length > 0 && (
                    <SelectedSpecialization
                      jobListFilter={jobListFilter?.trackIds}
                      filterArray={filterArray[index]?.subSpecialisations}
                    />
                  )}
                  <RightArrowIcon />
                </MenuBoxStyled>
              </MenuContainer>
            );
          })}
        </BoxContainer>
      </JobSpecializationContainer>
      <JobSpecializationMenu
        type={type}
        jobListFilter={jobListFilter}
        filterArray={filterArray[currentSpecialization]?.subSpecialisations}
        checkboxValueChange={checkboxValueChange}
        selectedFilters={selectedFilters}
        updateSelectAllJobFilter={updateSelectAllJobFilter}
        closeSubMenu={closeSubMenu}
        show={showSubSpecialisation}
      />
      <ButtonContainer
        justifyContent={"space-between"}
        padding={"1.5rem 1.5rem 1rem"}
      >
        <FilterActionButton
          onClick={() => handleCancelClick(type)}
          variant={"outline"}
        >
          Cancel
        </FilterActionButton>
        <FilterActionButton
          variant={"filled"}
          onClick={() => onTriggerLoadJob(jobListFilter)}
        >
          Apply
        </FilterActionButton>
      </ButtonContainer>
    </SpecializationContainer>
  );
}

function SelectedSpecialization(props) {
  const { jobListFilter, filterArray } = props;

  const count = jobListFilter?.filter((num) =>
    filterArray?.some((obj) => obj?.id === num)
  )?.length;

  return count > 0 ? <NumberedStyled>{count}</NumberedStyled> : null;
}

//render select all and deselect all button
function RenderButton(props) {
  const {
    filterArray,
    selectedFilters,
    updateSelectAllJobFilter,
    jobListFilter,
  } = props;

  const dispatch = useDispatch();

  let specializationIds = [];

  function handleSelectAll() {
    let cJobListFilter = { ...jobListFilter };
    const fullSpecList = filterArray?.flatMap((item) =>
      item?.subSpecialisations?.map((subItem) => ({ id: subItem.id }))
    );

    fullSpecList?.forEach((obj) => {
      specializationIds.push(obj.id);
    });

    cJobListFilter["trackIds"] = specializationIds;

    dispatch(updateSelectAllJobFilter(cJobListFilter));
  }

  function handleDeselectAll() {
    let cJobListFilter = { ...jobListFilter };
    cJobListFilter["trackIds"] = specializationIds;

    dispatch(updateSelectAllJobFilter(cJobListFilter));
  }

  const isEmpty = selectedFilters?.length > 0;

  return (
    <FilterActionButton
      onClick={isEmpty ? () => handleDeselectAll() : () => handleSelectAll()}
      variant="text"
    >
      {isEmpty ? "Deselect All" : "Select All"}
    </FilterActionButton>
  );
}

export default JobSpecialization;
