import useMediaQuery from "@mui/material/useMediaQuery";
import { useClickAway } from "@uidotdev/usehooks";
import { useRouter } from "next/router";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../assets/colors";
import { jobTypes } from "../../../helpers/constant";
import {
  calibrateSalary,
  convertExpectedSalary,
} from "../../../helpers/data_management";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useGenerateJobListUrl from "../../../hooks/generateJobListUrl";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import { updateJobListFilter } from "../../../redux/actions/job_action";
import { updateShowSignInModalStatus } from "../../../redux/actions/navbar_action";
import { checkAuthentication } from "../../../redux/actions/user_action";
import { store } from "../../../redux/stores/store";
import SharedMobileFilterDialog from "../../shared/SharedMobileFilterDialog/SharedMobileFilterDialog";
import JobSpecialization from "../JobSpecialization/JobSpecialization";
import JobSearchOptionsMenu from "./JobSearchOptionsMenu";
import {
  BadgeStyled,
  BoxContainer,
  ButtonContainer,
  ButtonStyled,
  CheckStyled,
  CheckboxStyled,
  CheckedIcon,
  ContentTextStyled,
  CountContainer,
  DropdownContainer,
  DropdownHeader,
  DropdownOptionContainer,
  FilterActionButton,
  FilterIcon,
  FilterMenu,
  InfoIconStyled,
  InternshipIcon,
  JobSearchOptionsMenuBox,
  JobSearchOptionsWrapper,
  LinkStyled,
  RemoteIcon,
  SalaryFilterContainer,
  SelectIcon,
  SliderContainer,
  SliderStyled,
  SnackbarTextStyled,
  TextStyled,
  TooltipStyled,
  UncheckedIcon,
  useStyles,
} from "./styles";

function JobSearchOptions(props) {
  const {
    updateJobListFilterFunction,
    jobPreferenceToggle,
    setJobPreferenceToggle,
  } = props;

  const router = useRouter();
  const dispatch = useDispatch();
  const { filterToSlug } = useGenerateJobListUrl();
  const [triggerSnackbarFunc] = useTriggerSnackbar();

  const isDesktop = useMediaQuery("(min-width:1280px)");
  const isMobile = useMediaQuery("(max-width:640px)");

  const filterMenuAnchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterMenuPosition, setFilterMenuPosition] = useState({ top: 0 });
  const [currentPanel, setCurrentPanel] = useState("");
  const [showSubSpecialisation, setShowSubSpecialisation] = useState(false);
  const [openMobileFilter, setOpenMobileFilter] = useState(false);

  const open = Boolean(anchorEl);
  const classes = useStyles();

  const currentUser = useSelector((state) => state?.user?.user);
  const jobListFilter = useSelector((state) => state.jobs.jobListFilter);
  const specialisationList = useSelector((state) => state.jobs.specialisation);
  const experienceLevelsList = useSelector(
    (state) => state.jobs.experienceLevels
  );
  const stateRegionsList = useSelector((state) => state.jobs.stateRegions);
  const jobTypesList = jobTypes;

  const globalHireStatus = jobListFilter?.globalHire;

  const internStatus = jobListFilter?.jobTypeIds?.includes(4);

  const remoteStatus = jobListFilter?.stateRegions?.includes("Remote");

  function getFilter(jobListFilter, type) {
    if (!jobListFilter) {
      return;
    }
    switch (type) {
      case "keyword": {
        return jobListFilter["keyword"];
      }
      case "specialisation": {
        return jobListFilter["trackIds"];
      }
      case "state": {
        return jobListFilter["stateRegions"];
      }
      case "job-type": {
        return jobListFilter["jobTypeIds"];
      }
      case "experience": {
        return jobListFilter["experienceIds"];
      }
      case "expectedSalary": {
        return jobListFilter["expectedSalary"];
      }
      case "globalHire": {
        return jobListFilter["globalHire"];
      }
    }
  }

  const specializationLength = getFilter(
    jobListFilter,
    "specialisation"
  )?.length;
  const stateLength = getFilter(jobListFilter, "state")?.filter(
    (state) => state !== "Remote"
  ).length; //Exclude Remote filter so (Filter) will not active
  const jobTypeLength = getFilter(jobListFilter, "job-type")?.length;
  const experienceLevelLength = getFilter(jobListFilter, "experience")?.length;
  const expected_salary =
    jobListFilter && jobListFilter["expectedSalary"]
      ? jobListFilter["expectedSalary"]
      : 0;
  const expectedSalaryExist =
    expected_salary === 0 || isNaN(expected_salary) ? 0 : 1;
  const globalHireExist = getFilter(jobListFilter, "globalHire");

  const totalFilter =
    (isNaN(specializationLength) ? 0 : specializationLength) +
    (isNaN(stateLength) ? 0 : stateLength) +
    (isNaN(jobTypeLength) ? 0 : jobTypeLength) +
    (isNaN(experienceLevelLength) ? 0 : experienceLevelLength) +
    expectedSalaryExist +
    (globalHireExist ? 1 : 0);

  function handleInternFilter() {
    const cFilter = jobListFilter;
    if (internStatus) {
      const filteredFilter = cFilter.jobTypeIds.filter((id) => id !== 4);
      cFilter.jobTypeIds = filteredFilter;
    } else {
      cFilter.jobTypeIds
        ? cFilter.jobTypeIds.push(4)
        : (cFilter.jobTypeIds = [4]);
    }
    handleApplyFilters(cFilter);
  }

  function handleRemoteFilter() {
    const cFilter = jobListFilter;
    if (remoteStatus) {
      const filteredFilter = cFilter.stateRegions?.filter(
        (state) => state !== "Remote"
      );
      cFilter.stateRegions = filteredFilter;
    } else {
      cFilter.stateRegions
        ? cFilter.stateRegions.push("Remote")
        : (cFilter.stateRegions = ["Remote"]);
    }
    handleApplyFilters(cFilter);
  }

  function handleGlobalHire() {
    const cFilter = jobListFilter;
    if (globalHireStatus) {
      cFilter.globalHire = false;
    } else {
      cFilter.globalHire = true;
    }
    handleApplyFilters(cFilter);
  }

  function handleOpenFilters(event) {
    setAnchorEl(event.currentTarget);

    // getBoundingClientRect return the current position of element on the actual screen
    // usage: calculate menu position accurately to avoid overlapping
    const filterMenuAnchorPosition =
      filterMenuAnchorRef.current?.getBoundingClientRect();

    setFilterMenuPosition((prev) => ({
      ...prev,
      top: filterMenuAnchorPosition.top,
    }));
  }

  function handleCloseFilters() {
    setCurrentPanel("");
    setAnchorEl(null);
  }

  function handleApplyFilters(filterParams) {
    setCurrentPanel("");
    setAnchorEl(null);
    const { slug, specSlugExist } = filterToSlug(filterParams);

    const filterQuery = {
      specialisation:
        !specSlugExist && filterParams?.trackIds?.length > 0
          ? filterParams.trackIds
          : [],
      state:
        filterParams?.stateRegions?.length > 0 ? filterParams.stateRegions : [],
      "job-type":
        filterParams?.jobTypeIds?.length > 1 ? filterParams.jobTypeIds : [],
      experience:
        filterParams?.experienceIds?.length > 0
          ? filterParams.experienceIds
          : [],
      salary:
        filterParams?.expectedSalary !== 0 && filterParams?.expectedSalary
          ? filterParams.expectedSalary
          : [],
    };

    router.push(
      {
        pathname: slug,
        query: filterQuery,
      },
      undefined,
      { shallow: true }
    );
  }

  const handleSliderChange = (event, newValue) => {
    updateJobListFilterFunction("expectedSalary", newValue);
  };

  const checkboxValueChange = (key, value) => (event) => {
    let cValue = value == -1 ? value : key == "state" ? value.state : value.id;

    if (key != "state" && value != -1) {
      try {
        cValue = parseInt(cValue);
      } catch (error) {
        console.error(error);
      }
    }

    if (updateJobListFilterFunction) {
      updateJobListFilterFunction(key, cValue);
    }
  };

  const getFilterValue = (type, filter) => {
    switch (type) {
      case "state": {
        return filter.state;
      }
      case "globalHire": {
        return jobListFilter["globalHire"];
      }
      default: {
        return parseInt(filter.id);
      }
    }
  };

  const isFilterSelected = (selectedFilters, filter) => {
    if (!selectedFilters) {
      return false;
    }

    if (filter == -1) {
      if (selectedFilters) {
        return selectedFilters.length == 0;
      } else {
        return true;
      }
    } else if (selectedFilters) {
      const index = selectedFilters?.indexOf(filter);
      return index != -1;
    }

    return false;
  };

  function handleCancelClick() {
    setCurrentPanel("");
  }

  function checkLogin() {
    if (!props?.isSignedIn(true)) {
      if (!props?.isSignedIn(false)) {
      }
    } else {
      return true;
    }
  }

  function getJobPreference() {
    let selectedFilters = JSON.parse(
      JSON.stringify(store.getState().jobs.jobListFilter)
    );

    const userSpecialisation = currentUser?.trackIds;
    const userJobState = currentUser?.stateRegionNames;
    const userJobType = currentUser?.jobTypeIds;
    const userExperience = currentUser?.experienceIds;
    const userExpSalary = currentUser?.expectedSalary;

    if (
      userSpecialisation?.length > 0 ||
      userJobState?.length > 0 ||
      userJobType?.length > 0 ||
      userExperience?.length > 0 ||
      userExpSalary > 0
    ) {
      const filterRouteQuery = [];

      if (userSpecialisation?.length > 0) {
        selectedFilters["trackIds"] = [...userSpecialisation];
        filterRouteQuery["specialisation"] = [...userSpecialisation];
      }

      if (userJobState?.length > 0) {
        selectedFilters["stateRegions"] = [...userJobState];
        filterRouteQuery["state"] = [...userJobState];
      }

      if (userJobType?.length > 0) {
        selectedFilters["jobTypeIds"] = [...userJobType];
        filterRouteQuery["job-type"] = [...userJobType];
      }

      if (userExperience?.length > 0) {
        selectedFilters["experienceIds"] = [...userExperience];
        filterRouteQuery["experience"] = [...userExperience];
      }

      if (userExpSalary > 0) {
        selectedFilters["expectedSalary"] = [
          convertExpectedSalary(userExpSalary),
        ];
        filterRouteQuery["salary"] = [convertExpectedSalary(userExpSalary)];
      }

      // just need to update once here
      dispatch(updateJobListFilter(selectedFilters));

      router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          ...filterRouteQuery,
        },
      });

      setJobPreferenceToggle && setJobPreferenceToggle(true);
      return true;
    } else {
      setJobPreferenceToggle && setJobPreferenceToggle(false);
      return false;
    }
  }

  function clearJobPreference() {
    let selectedFilters = JSON.parse(
      JSON.stringify(store.getState().jobs.jobListFilter)
    );

    selectedFilters["trackIds"] = [];
    selectedFilters["stateRegions"] = [];
    selectedFilters["jobTypeIds"] = [];
    selectedFilters["experienceIds"] = [];
    selectedFilters["expectedSalary"] = 0;

    dispatch(updateJobListFilter(selectedFilters));

    router.push("/jobs");

    handleJobPreferenceTracking();
    setJobPreferenceToggle(false);
  }

  function triggerSnackbar(jobPreferenceExist) {
    jobPreferenceExist
      ? (triggerSnackbarFunc({
          snackbarMessage: (
            <SnackbarTextStyled>
              Your job listing page has now been refreshed and displaying Job
              Titles according to your Job Preference.{" "}
              <LinkStyled href="/profile#job-preferences">
                Edit Job Preference?
              </LinkStyled>
            </SnackbarTextStyled>
          ),
          severity: "success",
        }),
        handleJobPreferenceTracking(),
        setJobPreferenceToggle(true))
      : triggerSnackbarFunc({
          snackbarMessage: (
            <SnackbarTextStyled>
              No job preferences set. Personalize your job recommendations in
              Settings.{" "}
              <LinkStyled href="/profile#job-preferences">
                Edit Job Preference?
              </LinkStyled>
            </SnackbarTextStyled>
          ),
          severity: "warning",
        });
  }

  function handleJobPreferenceTracking() {
    sendTrackingEvent({
      event: "CE_click-job-preference-toggle-jlp",
      action: jobPreferenceToggle ? "off" : "on",
    });
  }

  function handleJobPreferenceToggle(event) {
    event.target.checked
      ? checkLogin() && triggerSnackbar(getJobPreference())
      : clearJobPreference();
  }

  const handleShow = (panel) => {
    currentPanel === panel ? setCurrentPanel("") : setCurrentPanel(panel);
    setShowSubSpecialisation(false);
  };

  return (
    <JobSearchOptionsWrapper>
      <Fragment>
        <BoxContainer gap={"0.375rem"}>
          {/* Hide Job Preference */}

          {/* <ButtonStyled
            aria-controls="job-preferences-toggle"
            $jobPreference
            $active={jobPreferenceToggle}
          >
            <BoxContainer>
              Job Preference
              <BetaTextStyled>BETA</BetaTextStyled>
            </BoxContainer>
            <JobPreferenceTooltip />
            <JobPreferenceToggle
              checked={jobPreferenceToggle}
              onChange={handleJobPreferenceToggle}
            />
          </ButtonStyled> */}
          <BoxContainer gap={"0.375rem"}>
            <BadgeStyled
              badgeContent={totalFilter}
              ref={filterMenuAnchorRef}
              max={9}
              color="primary"
            >
              <ButtonStyled
                aria-controls="job-filters-menu"
                aria-haspopup="true"
                onClick={(e) => handleOpenFilters(e)}
                onClose={(e) => handleCloseFilters(e)}
                $job_filter_clicked={anchorEl}
                $active={totalFilter > 0}
              >
                {!isMobile ? "Filter" : null}
                <FilterIcon />
              </ButtonStyled>
            </BadgeStyled>
            <ButtonStyled
              aria-controls="job-internship-filter"
              onClick={() => {
                handleInternFilter();
              }}
              $active={internStatus}
            >
              {!isMobile ? "Internship" : null}
              <InternshipIcon />
            </ButtonStyled>
            <ButtonStyled
              aria-controls="job-remote-filter"
              onClick={() => {
                handleRemoteFilter();
              }}
              $active={remoteStatus}
            >
              {!isMobile && "Remote Work"}
              <RemoteIcon />
            </ButtonStyled>
          </BoxContainer>
        </BoxContainer>

        <FilterMenu
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseFilters}
          className={classes.scrollBar}
          $position={filterMenuPosition}
          PaperProps={{
            style: {
              maxHeight: "78%",
            },
          }}
          unmountOnExit
        >
          {/* Global Hire Filter */}
          <DropdownHeader
            onClick={() => handleGlobalHire()}
            $active={globalHireStatus}
            sx={{
              color: globalHireStatus
                ? "rgba(0, 0, 0, 0.87)"
                : "rgba(0, 0, 0, 0.60)",
              justifyContent: "flex-start",
              gap: "0.875rem",
              height: "auto",
              padding: "0.5rem 1rem",
            }}
          >
            <CheckboxStyled
              sx={{ padding: "0" }}
              icon={<UncheckedIcon />}
              checked={globalHireStatus}
              checkedIcon={
                <CheckedIcon>
                  <CheckStyled />
                </CheckedIcon>
              }
            />
            <BoxContainer flexDirection={"column"} alignItems={"flex-start"}>
              <TextStyled
                fontSize={"0.875rem"}
                lineHeight={"1.25rem"}
                letterSpacing={"0.112px"}
              >
                Global Hire only
              </TextStyled>
              <TextStyled fontSize={"0.625rem"} lineHeight={"1.25rem"}>
                Filter for jobs that accepts international talents.
              </TextStyled>
            </BoxContainer>
          </DropdownHeader>

          {/* Specialisation Filter */}
          <FilterDropdown
            title={"specialisation"}
            panel={"panel1"}
            currentPanel={currentPanel}
            count={specializationLength}
            handleShow={handleShow}
          >
            <JobSpecialization
              handleCancelClick={handleCancelClick}
              checkboxValueChange={checkboxValueChange}
              filterArray={specialisationList}
              getFilter={getFilter}
              onTriggerLoadJob={handleApplyFilters}
              showSubSpecialisation={showSubSpecialisation}
              setShowSubSpecialisation={setShowSubSpecialisation}
            />
          </FilterDropdown>

          {/* State Filter */}
          <FilterDropdown
            title={"State / Region"}
            panel={"panel2"}
            currentPanel={currentPanel}
            count={stateLength}
            handleShow={handleShow}
          >
            <JobSearchOptionsMenu
              id={"job-state-menu"}
              jobListFilter={jobListFilter}
              type="state"
              filterArray={stateRegionsList}
              handleCancelClick={handleCancelClick}
              checkboxValueChange={checkboxValueChange}
              getFilter={getFilter}
              getFilterValue={getFilterValue}
              isFilterSelected={isFilterSelected}
              onTriggerLoadJob={handleApplyFilters}
            />
          </FilterDropdown>

          {/* Job Types Filter */}
          <FilterDropdown
            title={"job types"}
            panel={"panel3"}
            currentPanel={currentPanel}
            count={jobTypeLength}
            handleShow={handleShow}
          >
            <JobSearchOptionsMenu
              id={"job-type-menu"}
              jobListFilter={jobListFilter}
              type="job-type"
              filterArray={jobTypesList}
              handleCancelClick={handleCancelClick}
              checkboxValueChange={checkboxValueChange}
              getFilter={getFilter}
              getFilterValue={getFilterValue}
              isFilterSelected={isFilterSelected}
              onTriggerLoadJob={handleApplyFilters}
            />
          </FilterDropdown>

          {/* Experience Filter */}
          <FilterDropdown
            title={"experience"}
            panel={"panel4"}
            currentPanel={currentPanel}
            count={experienceLevelLength}
            handleShow={handleShow}
          >
            <JobSearchOptionsMenu
              id={"experience-level-menu"}
              jobListFilter={jobListFilter}
              type="experience"
              filterArray={experienceLevelsList}
              handleCancelClick={handleCancelClick}
              checkboxValueChange={checkboxValueChange}
              getFilter={getFilter}
              getFilterValue={getFilterValue}
              isFilterSelected={isFilterSelected}
              onTriggerLoadJob={handleApplyFilters}
            />
          </FilterDropdown>

          {/* Salary Filter */}
          <FilterDropdown
            title={"salary"}
            panel={"panel5"}
            currentPanel={currentPanel}
            count={expected_salary}
            handleShow={handleShow}
          >
            <JobSearchOptionsMenuBox id="salary-menu">
              <SalaryFilterContainer>
                <TextStyled
                  fontSize={"0.875rem"}
                  fontWeight={"700"}
                  textTransform={"capitalize"}
                >
                  minimum monthly salary
                </TextStyled>
                <SliderContainer>
                  <SliderStyled
                    defaultValue={0}
                    aria-labelledby="salary-range-slider"
                    step={1}
                    marks
                    min={0}
                    max={50}
                    onChange={handleSliderChange}
                    value={expected_salary}
                  />
                </SliderContainer>
                <ContentTextStyled>
                  {process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
                    ? "RM " + calibrateSalary(expected_salary)
                    : calibrateSalary(expected_salary) + " SGD"}
                </ContentTextStyled>
              </SalaryFilterContainer>

              <ButtonContainer
                justifyContent={"space-between"}
                padding={"1.5rem 1.5rem 1rem"}
              >
                <FilterActionButton
                  onClick={() => handleCancelClick()}
                  variant="outline"
                >
                  Cancel
                </FilterActionButton>
                <FilterActionButton
                  onClick={() => handleApplyFilters(jobListFilter)}
                  variant="filled"
                >
                  Apply
                </FilterActionButton>
              </ButtonContainer>
            </JobSearchOptionsMenuBox>
          </FilterDropdown>
        </FilterMenu>
        {isDesktop ? null : (
          <SharedMobileFilterDialog
            openFilterDialog={openMobileFilter}
            setOpenFilter={setOpenMobileFilter}
          />
        )}
      </Fragment>
    </JobSearchOptionsWrapper>
  );
}

function JobPreferenceTooltip() {
  const dispatch = useDispatch();
  const router = useRouter();

  const isDesktop = useMediaQuery("(min-width:1280px)");

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipRef = useClickAway(() => {
    setIsTooltipOpen(false);
  });

  function handlePopoverRedirect() {
    if (!checkAuthentication()) {
      return dispatch(updateShowSignInModalStatus(true));
    }
    router.push("/profile#job-preferences");
  }

  function handleTooltipClick() {
    setIsTooltipOpen(!isTooltipOpen);
  }

  return isDesktop ? (
    <TooltipStyled
      PopperProps={{
        sx: {
          margin: "-0.5rem 0 0 -2.1rem !important",
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            width: "210px",
            borderRadius: "0.5rem",
            border: "2px solid",
            color: Color.black,
            backgroundColor: Color.white,
            padding: "0.35rem",
          },
        },
      }}
      title={
        <Fragment>
          <TextStyled
            fontSize={"0.625rem"}
            fontWeight={"600"}
            lineHeight={"0.85rem"}
          >
            Activate this toggle to instantly filter jobs according to your
            preferences.
          </TextStyled>
          <LinkStyled onClick={handlePopoverRedirect}>
            Edit Your Preference? ✨
          </LinkStyled>
        </Fragment>
      }
    >
      <InfoIconStyled />
    </TooltipStyled>
  ) : (
    <TooltipStyled
      ref={tooltipRef}
      open={isTooltipOpen}
      PopperProps={{
        sx: {
          margin: "-0.5rem 0 0 -2.1rem !important",
        },
      }}
      componentsProps={{
        tooltip: {
          sx: {
            width: "210px",
            borderRadius: "0.5rem",
            border: "2px solid",
            color: Color.black,
            backgroundColor: Color.white,
            padding: "0.35rem",
          },
        },
      }}
      title={
        <Fragment>
          <TextStyled
            fontSize={"0.625rem"}
            fontWeight={"600"}
            lineHeight={"0.85rem"}
          >
            Activate this toggle to instantly filter jobs according to your
            preferences.
          </TextStyled>
          <LinkStyled onClick={handlePopoverRedirect}>
            Edit Your Preference? ✨
          </LinkStyled>
        </Fragment>
      }
    >
      <InfoIconStyled onClick={() => handleTooltipClick()} />
    </TooltipStyled>
  );
}

function FilterDropdown({
  title,
  panel,
  currentPanel,
  handleShow,
  count,
  children,
}) {
  const noScroll = panel === "panel1" || panel === "panel2";

  function getFilterCount() {
    if (title === "salary") {
      const salary = calibrateSalary(count);

      return process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my"
        ? "RM " + salary
        : salary + " SGD";
    } else {
      return count;
    }
  }

  const appliedFilterCount = getFilterCount();

  return (
    <DropdownContainer>
      <DropdownHeader
        onClick={() => handleShow(panel)}
        $active={count > 0}
        open={currentPanel === panel}
      >
        <BoxContainer alignItems={"center"} gap={"0.625rem"}>
          <TextStyled
            color={"rgba(0, 0, 0, 0.87)"}
            fontSize={"0.875rem"}
            fontWeight={"400"}
            lineHeight={"1.25rem"}
            letterSpacing={"0.112px"}
            textTransform={"capitalize"}
          >
            {title}
          </TextStyled>
          {count > 0 && <CountContainer>{appliedFilterCount}</CountContainer>}
        </BoxContainer>
        <SelectIcon open={currentPanel === panel} />
      </DropdownHeader>
      <DropdownOptionContainer
        open={currentPanel === panel}
        noScroll={!noScroll}
      >
        {children}
      </DropdownOptionContainer>
    </DropdownContainer>
  );
}

export default JobSearchOptions;
